import constants from "../Types/actionTypes";

const initialState = {
    heatMapDetail:[],
    HomeData:{},
    BillBoardData:{},
    UserData:{},
    billBoardData:{},
    offlinebillBoardData:{},
    cameraBillBoard:{},
    loginData:{},
    billBoardDetail:{}
};

const  rootReducer = (state = initialState, action) => {
    switch(action.type) {
        case constants.heatMapDetail:
            return {
                ...state,
                heatMapDetail: action.data,
            };
        case constants.HomeData:
            return {
                ...state,
                HomeData: action.data,
            };
        case constants.BillBoardData:
            return {
                ...state,
                BillBoardData: action.data,
            };
        case constants.UserData:
            return {
                ...state,
                UserData: action.data,
            };
        case constants.billBoardData:
            return {
                ...state,
                billBoardData: action.data,
            };
        case constants.offlinebillBoardData:
            return {
                ...state,
                offlinebillBoardData: action.data,
            };
        case constants.cameraBillBoard:
            return {
                ...state,
                cameraBillBoard: action.data,
            };
        case constants.loginData:
            return {
                ...state,
                loginData: action.data,
            };
        case constants.billBoardDetail:
            return {
                ...state,
                billBoardDetail: action.data,
            };
        default:
            return state;
    }
};

export default rootReducer;
