import { Card, CardContent, Container, Grid } from "@mui/material"
import Sidebar from "./sidebar"
import Permission from "../Permission"

export default function SettingLayout({ permission, children }) {

    return (
        <div className="my-3">
            <Container maxWidth="xl" sx={{ height: "100%" }}>
                <Card
                    variant="outlined"
                    className="card-content"
                    sx={{ minHeight: "100%" }}
                >
                    <CardContent>
                        <Grid container spacing={2}>
                            <Sidebar />
                            {permission ? (
                                <Permission permission={permission}>{children}</Permission>
                            ) : (
                                children
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )
}