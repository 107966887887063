import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import DownloadIcon from "@mui/icons-material/Download"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { CopyToClipboard } from "react-copy-to-clipboard"
import {
  deleteApk,
  downloadApk,
  getIosLink,
  getLatestApk,
  uploadApk,
  uploadIosLink,
} from "../../../api/settings"
import Loader from "../../loader"
import { ALERT_MESSAGES } from "../../../constants/common"
import upload from "../../../images/upload.svg"
import APKImg from "../../../images/apk-1.png"
import closeIcon from "../../../images/action.png"
import { formatDateUtil } from "../../../utils/dates"
import DeleteDialogBox from "../../dialogueBox/ConfirmDialogBox"
import ConfirmDialogBox from "../../dialogueBox/ConfirmDialogBox"

export default function ManageApps({ platform }) {
  const [state, setState] = useState({
    isUploadApkLoading: false,
    isDeleteApkLoading: false,
    latestApks: [],
    isGetApkLoading: false,
    iosLink: "",
    iosLinkData: "",
    isGetIosLinkLoading: false,
    isUploadIosLinkLoading: false,
    isDownloadApkLoading: false,
    apkFile: "",
    deleteDialog: false,
  })

  const handleUploadApk = async (e) => {
    if (!e.target.files.length) return
    const loading = toast.loading("Apk Uploading, please wait...")
    setState((prev) => ({ ...prev, isUploadApkLoading: true }))
    const formData = new FormData()
    formData.append("platform", platform)
    formData.append(`apk-${platform}`, e.target.files[0])
    const response = await uploadApk(formData)
    if (response.remote === "success") {
      toast.success(response.data.message || "Apk uploaded successfully")
    } else {
      toast.error(response.data.message || ALERT_MESSAGES.fallbackError)
    }
    toast.dismiss(loading)
    setState((prev) => ({ ...prev, isUploadApkLoading: false }))
    handleGetLatestApks()
    e.target.value = []
  }

  const handleGetLatestApks = async () => {
    setState((prev) => ({ ...prev, isGetApkLoading: true }))
    const { data } = await getLatestApk({ platform })
    setState((prev) => ({
      ...prev,
      isGetApkLoading: false,
      latestApks: data.files,
    }))
  }

  const handleDeleteApk = async (fileName) => {
    setState((prev) => ({ ...prev, isDeleteApkLoading: true }))
    const loading = toast.loading("Deleting, please wait...")
    const { data } = await deleteApk({ fileName, platform })
    setState((prev) => ({
      ...prev,
      isDeleteApkLoading: false,
    }))
    toast.dismiss(loading)
    toast.success(data.message || "Apk deleted successfully")
    setState((prev) => ({ ...prev, deleteDialog: false }))
    handleGetLatestApks()
  }

  const handleUploadIosLink = async () => {
    setState((prev) => ({ ...prev, isUploadIosLinkLoading: true }))
    const loading = toast.loading("Uploading, please wait...")
    const response = await uploadIosLink({
      link: state.iosLink,
      platform,
    })
    if (response.remote === "success") {
      setState((prev) => ({ ...prev, iosLink: response.data.files }))
      toast.success(response.data.message || "Link uploaded successfully")
    } else {
      toast.error(response.data.message || ALERT_MESSAGES.fallbackError)
    }
    setState((prev) => ({ ...prev, isUploadIosLinkLoading: false }))
    toast.dismiss(loading)
    handleGetIosLink()
  }

  const handleGetIosLink = async () => {
    setState((prev) => ({ ...prev, isGetIosLinkLoading: true }))
    const { data } = await getIosLink({ platform })
    setState((prev) => ({
      ...prev,
      isGetIosLinkLoading: false,
      iosLinkData: data.data,
    }))
  }

  const handleDownloadApk = async (fileName) => {
    setState((prev) => ({ ...prev, isDownloadApkLoading: true }))
    const loading = toast.loading("APk Downloading, please wait...")
    const res = await downloadApk({ apkName: fileName, platform })
    if (res.remote === "success") {
      const blob = res.data // Directly assign the blob
      const downloadUrl = window.URL.createObjectURL(blob) // Create a download URL for the Blob
      const link = document.createElement("a")
      link.href = downloadUrl
      link.download = platform // Set a default fileName for the APK
      document.body.appendChild(link)
      link.click()
      // Clean up by revoking the Blob URL and removing the link
      window.URL.revokeObjectURL(downloadUrl)
      document.body.removeChild(link)
      setTimeout(() => {}, 2000)
    }
    toast.dismiss(loading)
    toast.success(res.data.message || "Downloaded successfully")
    setState((prev) => ({ ...prev, isDownloadApkLoading: false }))
  }

  useEffect(() => {
    handleGetLatestApks()
    handleGetIosLink()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid item lg={10} xs={12}>
        <Box
          sx={{
            maxWidth: "790px",

            "@media(max-width:992px)": {
              maxWidth: "100%",
            },
          }}
        >
          <Card
            variant={false}
            sx={{
              border: "1px solid #D8DAE1CC",
              borderRadius: "10px",
              overflow: "hidden",
              mb: 3,
            }}
          >
            <CardContent
              sx={{
                "&:last-child": {
                  pb: 2,
                },
              }}
            >
              <Box
                sx={{ fontWeight: "600", fontSize: "16px", color: "#0B0B0B" }}
              >
                Upload Android App
              </Box>
              <Box sx={{ fontSize: "14px", color: "#6D6D6D" }}>
                Add your android apk here, max size of the apk in 50mb
              </Box>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "2px dashed #1849D6",
                  mt: 2,
                  p: 2,
                  textAlign: "center",
                  position: "relative",
                  "& input": {
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    zIndex: 1,
                  },
                }}
              >
                <input
                  type="file"
                  disabled={state.isUploadApkLoading}
                  accept=".apk"
                  onChange={handleUploadApk}
                />
                <Stack
                  direction={"column"}
                  spacing={2}
                  justifyContent={"center"}
                >
                  <Box>
                    <img alt="" src={upload} />
                  </Box>
                  <Box
                    sx={{
                      fontSize: "16px",
                      color: "#222943CC",
                      fontWeight: 500,
                    }}
                  >
                    Drag your file(s) to start uploading
                  </Box>
                  <Box>
                    <Divider sx={{ maxWidth: "200px", mx: "auto" }}>OR</Divider>
                    <Button
                      sx={{
                        borderRadius: "5px",
                        background: "#3AC4F2",
                        textTransform: "capitalize",
                        color: "#fff",
                        px: "16px",
                        fontSize: "16px",
                        fontWeight: 500,
                        mt: 1,
                        "&:hover": {
                          background: "#3AC4F2",
                        },
                      }}
                    >
                      Browse files
                    </Button>
                  </Box>
                </Stack>
              </Box>
              {/* ATTACHED APK */}
              <Box
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#3AC4F2",
                  my: 2,
                }}
              >
                Attached APk
              </Box>
              {state.isGetApkLoading ? (
                <Loader />
              ) : (
                state.latestApks.map((apk, i) => (
                  <Box
                    key={i}
                    sx={{
                      borderRadius: "8px",
                      p: 2,
                      border: "1px solid #E7E7E7",
                      mb: 2,
                    }}
                  >
                    <Stack
                      direction={"row"}
                      spacing={2}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems={"center"}
                      >
                        <img src={APKImg} alt="" />
                        <Box>
                          <Box sx={{ fontSize: "14px", fontWeight: 600 }}>
                            {apk.File}
                          </Box>
                          <Box
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#6D6D6D",
                            }}
                          >
                            Uploaded on: {formatDateUtil(apk.updatedOn)}
                          </Box>
                        </Box>
                      </Stack>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        disabled={state.isDownloadApkLoading}
                      >
                        <DownloadIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleDownloadApk(apk.File)}
                        />
                        <IconButton
                          disabled={state.isDeleteApkLoading}
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              apkFile: apk.File,
                              deleteDialog: true,
                            }))
                          }
                        >
                          <img src={closeIcon} alt="" />
                        </IconButton>
                      </Box>
                    </Stack>
                  </Box>
                ))
              )}
            </CardContent>
          </Card>
          {/* IOS Link */}
          <Card
            variant={false}
            sx={{
              border: "1px solid #D8DAE1CC",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <CardContent
              sx={{
                "&:last-child": {
                  pb: 2,
                },
              }}
            >
              <Box
                sx={{ fontWeight: "600", fontSize: "16px", color: "#0B0B0B" }}
              >
                IOS Link
              </Box>
              <Box sx={{ fontSize: "14px", color: "#6D6D6D" }}>
                Set your IOS app link
              </Box>
              <Box
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#3AC4F2",
                  mt: 2,
                  mb: 1,
                }}
              >
                Set IOS link
              </Box>
              <Box
                sx={{
                  "& input": {
                    width: "100%",
                    py: 1,
                    px: 2,
                    background: "#F4F8FB",
                    border: "1px solid #D8DAE180",
                    borderRadius: "5px",
                    outline: "none",
                    height: "50px",
                  },
                }}
              >
                <input
                  type="text"
                  placeholder="Paste your ios app link here"
                  onChange={(e) =>
                    setState((prev) => ({ ...prev, iosLink: e.target.value }))
                  }
                />
                <Box sx={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      borderRadius: "5px",
                      background: "#3AC4F2",
                      textTransform: "capitalize",
                      fontSize: "16px",

                      width: "138px",
                      mt: 1,
                      height: "50px",
                    }}
                    disabled={!state.iosLink || state.isUploadIosLinkLoading}
                    onClick={handleUploadIosLink}
                  >
                    Save
                  </Button>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#3AC4F2",
                      my: 2,
                    }}
                  >
                    Attached links
                  </Typography>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    alignItems={"center"}
                    sx={{
                      borderRadius: "5px",
                      border: "1px solid #11AF22",
                      p: 2,
                    }}
                    justifyContent={"space-between"}
                  >
                    <Box
                      sx={{
                        color: "#22294399",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      {state.iosLinkData}
                    </Box>
                    <CopyToClipboard
                      text={state.iosLinkData}
                      onCopy={() => toast.success("Url Copied Successfully.")}
                    >
                      <ContentCopyIcon sx={{ cursor: "pointer" }} />
                    </CopyToClipboard>
                  </Stack>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Dialog
        open={state.deleteDialog}
        onClose={() => setState((prev) => ({ ...prev, deleteDialog: false }))}
      >
        <ConfirmDialogBox
          icon="delete"
          disabled={state.isDeleteApkLoading}
          handleButton={() => {
            handleDeleteApk(state.apkFile)
          }}
          textMessage="Are you sue you want to delete this apk?"
          onClose={() => setState((prev) => ({ ...prev, deleteDialog: false }))}
          isLoading={state.isDeleteApkLoading}
        />
      </Dialog>
    </>
  )
}
