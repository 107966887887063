// src/redux/store/index.js
import { createStore, applyMiddleware,compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { encryptTransform } from 'redux-persist-transform-encrypt';
import rootReducer from '../Reducers/reducers';
import logger from "redux-logger";



const encryptionConfig = encryptTransform({
    secretKey: 'root',
    onError: function (error) {
      // Handle the error.
    },
})

const persistConfig = {
  key: "root",
  storage,
  transforms: [encryptionConfig] // this will encrypt the data in localStorage
};


const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewares = [logger];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
  