import React from "react";
import GoogleMap from "google-map-react";
import pin from "../../../images/map-pin.svg";

const Marker = (props) => {
  const { name, lat, lng } = props;
  return (
    <div
      className="pin"
      style={{ backgroundColor: "transparent", cursor: "pointer" }}
      title={name}
      onClick={() => {
        const url = `https://www.google.com/maps/dir//${lat},${lng}`;
        window.open(url, "_blank");
      }}
    >
      <img alt="pin" src={pin} />
    </div>
  );
};

export default function SimpleMap(props) {
  const [mark, setMark] = React.useState({});

  React.useEffect(() => {
    // console.log("<SimpleMap> props.markerPos", props.markerPos);
    if (props.markerPos)
      setMark({
        lng: Number(props.markerPos[1]) || 0,
        lat: Number(props.markerPos[0]) || 0,
      });
  }, [props]);

  const getMapOptions = (maps) => {
    return {
      disableDefaultUI: false,
      mapTypeControl: true,
      streetViewControl: true,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "on" }],
        },
      ],
    };
  };

  return Object.values(mark).length > 0 || props.edit ? (
    <GoogleMap
      onClick={(ev) => {
        if (!props.edit) {
          return;
        }
        props.setData([ev.lng, ev.lat]);
        setMark({ lng: ev.lng, lat: ev.lat });
      }}
      bootstrapURLKeys={{ key: "AIzaSyA-SGeGL2gFSI3MsiwMqshTNmmQleaOA7A" }}
      center={{
        lat: props.markerPos?.length > 1 ? props.markerPos[0] : 31,
        lng: props.markerPos?.length > 1 ? props.markerPos[1] : 34,
      }}
      zoom={9}
      options={getMapOptions}
    >
      {Object.values(mark).length > 0 && (
        <Marker {...mark} name={props?.label || "My Marker"} color="red" />
      )}
    </GoogleMap>
  ) : null;
}
