// export const GetSmtp = async () => {
//     const response = await api.request({
//       url: `/v1/`,
//       method: "GET",
//     });
//     return response;
//   };

import api from "./api"


export const uploadApk = async (data) => {
  const response = await api.request({
    url: `/v2/mobile-app/`,
    method: "POST",
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}
export const getLatestApk = async (data) => {
  const response = await api.request({
    url: `/v2/mobile-app?platform=${data.platform}`,
    method: "GET",
    data
  })
  return response
}
export const deleteApk = async (data) => {
  const response = await api.request({
    url: `/v2/mobile-app/delete-apk`,
    method: "Delete",
    data: {
      filename: data.fileName,
      platform: data.platform
    }
  })
  return response
}
export const uploadIosLink = async (data) => {
  const response = await api.request({
    url: `/v2/mobile-app/upload-ios/`,
    method: "POST",
    data: {
      link: data.link,
      platform: data.platform
    }
  })
  return response
}
export const getIosLink = async (data) => {
  const response = await api.request({
    url: `/v2/mobile-app/get-ios-link?platform=${data.platform}`,
    method: "GET",
    data
  })
  return response
}
export const downloadApk = async (data) => {
  const response = await api.request({
    url: `/v2/mobile-app/download-apk/${data.apkName}?platform=${data.platform}`,
    method: "GET",
    responseType: "blob",
    // data
  })
  return response
}
