import { CircularProgress, Stack } from '@mui/material'
import React from 'react'

function Loader({ sx }) {
  return (
    <Stack
      sx={{ display: "flex", margin: "auto", justifyContent: "center", ...sx }}
      spacing={2}
      direction="row"
    >
      <CircularProgress />
    </Stack>
  )
}

export default Loader
