import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

export const requestForToken = async () => {
  try {
    // Replace this firebaseConfig object with the configurations for the project you created on your firebase console.
    var firebaseConfig = {
      apiKey: "AIzaSyBVofhWa7jmc2X8oBX3tqqBUlI6C0eF_Ek",
      authDomain: "ledshoo.firebaseapp.com",
      projectId: "ledshoo",
      storageBucket: "ledshoo.appspot.com",
      messagingSenderId: "224408438994",
      appId: "1:224408438994:web:0f2e7db1c712078a90450b",
      measurementId: "G-PLQ4WVL7SD",
    };

    initializeApp(firebaseConfig);
    const messaging = getMessaging();
    return getToken(messaging, {
      vapidKey:
        "BMO5IoBHyp0ssHMZHrWIG4rBYN-CBiwFuO6YkqE1o8-JvJQkgGWvYyVU7lo4gwMHEMQ4DosCFA7wIPjaOS7PqYY",
    });
    //   .then((currentToken) => {
    //     if (currentToken) {
    //       console.log("current token for client: ", currentToken);
    //       // Perform any other neccessary action with the token
    //     } else {
    //       // Show permission request UI
    //       console.log(
    //         "No registration token available. Request permission to generate one."
    //       );
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("An error occurred while retrieving token. ", err);
    //   });
  } catch {}
};
