import React from 'react'
import ManageApps from '../manageApps'

function LedProApp() {
  return (
   <ManageApps platform="ledpro"/>
  )
}

export default LedProApp
