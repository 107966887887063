import { Button, Card, CardContent, Stack } from "@mui/material";
import { Form } from "react-bootstrap";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

const ContactDetails = ({ data }) => {
  return (
    <Card variant="false" className="card-content-2 h-100">
      <CardContent className="pb-3">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div className="card-content-title mb-3">Contact Details</div>
        </Stack>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Contact Name</Form.Label>
            <Form.Control
              disabled
              placeholder=" Charlene E. Milstead"
              className="form-control-sender form-control border-0"
              value={data.contact_name}
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Address</Form.Label>

            <Form.Control
              disabled
              placeholder="  1108 Angus Road New York"
              className="form-control-sender form-control border-0"
              value={data.address}
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone Number</Form.Label>
            <div className="form-control-sender form-control border-0 justify-content-between ms-auto">
              {data.hideData ? "XXXXXXXXXXX" : data.phone}{" "}
              {data.phone && (
                <a
                  href={`tel:${data.phone}`}
                  style={{ color: "rgba(10, 42, 72, 0.6)" }}
                >
                  <LocalPhoneIcon />
                </a>
              )}
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <div className="form-control-sender form-control border-0 justify-content-between ms-auto">
              {data.hideData ? "xxxxxxx@xxxx" : data.email}
              {data.email && (
                <a
                  href={`mailto:${data.email}`}
                  style={{ color: "rgba(10, 42, 72, 0.6)" }}
                >
                  <EmailIcon />
                </a>
              )}
            </div>
          </Form.Group>
        </Form>
      </CardContent>
    </Card>
  );
};
export default ContactDetails;
