export const MENU_ITEMS = [
  {
    page: "Manage Users",
    URL: "/my-profile/user-management",
    key: "userManagenemt",
    permission: "user-list-view",
    children: [
      {
        page: "LedPro Admins",
        url: "/my-profile/user-management/ledproAdmin-users",
        key: "ledproAdmin-users",
        permission: "user-list-view",
      },
      {
        page: "Guest Users",
        url: "/my-profile/user-management/guest-users",
        key: "guest-users",
        permission: "user-list-view",
      },
      {
        page: "Shoof Admins",
        url: "/my-profile/user-management/users",
        key: "users",
        permission: "user-list-view",
      },
    ],
  },
  {
    page: "Manage Permissions",
    URL: "/my-profile/user-permission-management",
    key: "userPermissionManagenemt",
    permission: "user-list-view",
    class: "dclass",
  },
  {
    page: "Billboard settings",
    URL: "/my-profile/billboard-settings",
    key: "billboardSettings",
    permission: "billboard-list-view",
  },
  {
    page: "Export reports",
    URL: "/my-profile/export-reports",
    key: "exportReports",
    permission: "report-list-view",
  },
  {
    page: "Services & Items",
    URL: "/my-profile/services-and-items",
    key: "servicesanditems",
    permission: "item-list-view",
  },
  {
    page: "Income Invoice",
    URL: "/my-profile/income-invoice",
    key: "income-invoice",
    permission: "view-income-invoice-list",
    children: [
      {
        page: "Awaiting Approval",
        url: "/my-profile/income-invoice/awaiting-approval",
        key: "awaiting-approval",
        permission: "view-income-invoice-list",
      },
      {
        page: "Awaiting Payment",
        url: "/my-profile/income-invoice/payment",
        key: "payment",
        permission: "view-income-invoice-list",
      },
      {
        page: "Paid",
        url: "/my-profile/income-invoice/paid",
        key: "paid",
        permission: "view-income-invoice-list",
      },
      {
        page: "Cancelled",
        url: "/my-profile/income-invoice/cancelled",
        key: "cancelled",
        permission: "view-income-invoice-list",
      },
    ],
  },
  {
    page: "Expenses Invoice",
    URL: "/my-profile/expenses-invoice",
    key: "expense-invoice",
    permission: "view-expenses-invoice-list",
    children: [
      {
        page: "Awaiting Approval",
        url: "/my-profile/expenses-invoice/awaiting-approval",
        key: "awaiting-approval",
        permission: "view-expenses-invoice-list",
      },
      {
        page: "Awaiting Payment",
        url: "/my-profile/expenses-invoice/payment",
        key: "payment",
        permission: "view-expenses-invoice-list",
      },
      {
        page: "Paid",
        url: "/my-profile/expenses-invoice/paid",
        key: "paid",
        permission: "view-expenses-invoice-list",
      },
      {
        page: "Cancelled",
        url: "/my-profile/expenses-invoice/cancelled",
        key: "cancelled",
        permission: "view-expenses-invoice-list",
      },
    ],
  },

  {
    page: "Links to applications",
    URL: "/manage-app",
    key: "manage-app",
    permission: "general-setting",
    children: [
      {
        page: "Shoof App",
        url: "/manage-app/shoof-app",
        key: "shoofApp",
        permission: "item-list-view",
      },
      {
        page: "LED pro App",
        url: "/manage-app/led-pro-app",
        key: "ledProApp",
        permission: "item-list-view",
      },
    ],
  },

  {
    page: "SMS Credit",
    URL: "/my-profile/sms-credit",
    key: "generalsettings",
    permission: "sms-credit",
  },
  {
    page: "Error Logs",
    URL: "/my-profile/error-logs",
    key: "generalsettings",
    permission: "general-setting",
  },
  {
    page: "General settings",
    URL: "/my-profile/general-settings",
    key: "generalsettings",
    permission: "general-setting",
  },
];

export const SettingViewPermissions = MENU_ITEMS.reduce((acc, item) => {
  if (!!item?.children?.length) {
    acc = acc.concat(
      item.children.map((i) => ({
        path: i.url,
        permission: i.permission,
      }))
    );
  } else {
    acc.push({ path: item.URL, permission: item.permission });
  }
  return acc;
}, []);
