import { styled } from "@mui/material";

export const Invoice = styled("div")(() => ({
  "& .MuiPaper-root": {
    boxShadow: "0px 4px 25px rgba(42, 89, 152, 0.05)",
    padding: "16px",
    borderRadius: "10px",
  },
  "& .MuiButton-outlined": {
    borderColor: "#7DE288",
    borderRadius: "5px",
    background: "#F5FFF6",
    fontSize: "18px",
    fontWeight: "700",
    color: "#7DE288",
    texttransform: "capitalize",
  },
  "& .MuiTypography-h1": {
    fontSize: "16px",
    fontWeight: "700",
    marginTop: "24px",
  },
  "& .MuiTypography-body1": {
    fontSize: "20px",
    fontWeight: "400",
    color: "#5A6474",
    lineHeight: "24px",
  },
  "& .MuiButton-text": {
    background: "#F4F4F4",
    border: "1px solid #DDDDDD",
    borderRadius: "5px",
    color: "#7B7B7B",
    texttransform: "capitalize",
    fontSize: "16px",
    fontWeight: "700",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  "& .MuiTypography-h4": {
    fontSize: "20px",
    fontWeight: "400",
    color: "#5A6474",
    marginTop: "24px",
  },
  "& .discount": { fontWeight: "700", textAlign: "right" },
  "& .MuiTableCell-root": { fontSize: "20px" },
  "& .MuiTypography-h5": {
    fontSize: "20px",
    fontWeight: "600",
    color: "#222943",
    marginBottom: "24px",
  },
}));
