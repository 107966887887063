import {
  Card,
  CardContent,
  Container,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { lazy } from "react";
import AdminSidebar from "./sidebar";
import Permission from "../Permission";
const Edit = lazy(() => import("./pending/Edit"));
const Uploader = lazy(() => import("./uploader"));
const PendingView = lazy(() => import("./pending/view"));
const Pending = lazy(() => import("./pending"));
const History = lazy(() => import("./history"));
const Library = lazy(() => import("./library"));
const DashBoard = lazy(() => import("./dashboard"));
const Ads = lazy(() => import("./advertisers"));
const AdsView = lazy(() => import("./advertisers/view"));

export const AdsLayout = ({ children, permission }) => {
  const matches = useMediaQuery("(max-width:768px)");
  return (
    <div className="my-3">
      <Container maxWidth="xl" sx={{ height: "100%" }}>
        <Card
          variant="outlined"
          className="card-content"
          sx={{ minHeight: "100%" }}
        >
          <CardContent>
            <Grid container spacing={2}>
              {!matches ? <AdminSidebar /> : ""}

              {permission ? (
                <Permission permission={permission}>{children}</Permission>
              ) : (
                children
              )}
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/ads/advertisers",
    element: <Ads />,
    permission: "ads-advertiser",
  },
  {
    path: "/ads/advertisers/:id",
    element: <AdsView />,
    permission: "ads-advertiser",
  },
  {
    path: "/ads/dashboard",
    element: <DashBoard />,
    permission: "ads-dashboard",
  },
  {
    path: "/ads/library",
    element: <Library />,
    permission: "ads-library",
  },
  {
    path: "/ads/history",
    element: <History />,
    permission: "ads-history",
  },
  {
    path: "/ads/pending",
    element: <Pending />,
    permission: "ads-pending",
  },
  {
    path: "/ads/:id",
    element: <PendingView />,
  },
  {
    path: "/pending/edit/:id",
    element: <Edit />,
    permission: "ads-library",
  },
  {
    path: "/ads/uploader",
    element: <Uploader />,
    permission: "ads-uploader",
  },
];
