export const PAYMENT_MODE = Object.freeze({
  BANK: "Payment by bank transfer",
  CARD: "Payment by credit card",
  CASH: "Payment by cash",
});

export const DISCOUNT_TYPE = Object.freeze({
  NO_DISCOUNT: "No Discount",
  BEFORE_TAX: "Discount Before Tax",
  AFTER_TAX: "Discount After Tax",
});

export const INVOICE_STATUS = Object.freeze({
  AWAITING_APPROVAL: "Awaiting Approval",
  AWAITING_PAYMENT: "Awaiting Payment",
  CANCELLED: "Cancelled",
  PAID: "Paid",
});

export const INVOICE_TYPE = Object.freeze({
  INCOME: "INCOME",
  EXPENSE: "EXPENSE",
});

export const USER_ROLE = Object.freeze({
  Partner: "Partner",
  Client: "Client",
  Employee: "Employee",
  Admin: "Admin",
  Advertiser: "Advertiser",
  SuperAdmin: "SuperAdmin",
  GuestUser: "GuestUser", 
  LedproAdmin: "LedproAdmin",
});

export const INVOICE_STATUS_URL = Object.freeze({
  AWAITING_APPROVAL: "awaiting-approval",
  AWAITING_PAYMENT: "payment",
  AWAITING_CANCELLED: "cancelled",
  PAID: "paid",
});

export const discountPersent = [
  {
    id: 1,
    name: "%",
    value: true,
  },
  {
    id: 2,
    name: "Fixed Amount",
    value: false,
  },
];

export const OTHER = [{ _id: "other", billboardName: "Other" }];
