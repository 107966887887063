import { Card, CardContent, Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "../../settings/sidebar/sidebar.css";
import { ADMIN_MENU } from "./helper";
import { useContext, useState } from "react";
import { PermissionContext } from "../../layout/user.provider";
const AdminSidebar = () => {
  const location = useLocation();
  const { userData } = useContext(PermissionContext);

  return (
    <>
      <Grid item xs={12} lg={2}>
        <Card
          variant="false"
          className="card-content"
          sx={{ minHeight: "100%" }}
        >
          <CardContent>
            <ul className="menu">
              {ADMIN_MENU.filter((item) =>
                userData.permission.includes(item.permission)
              ).map((item, index) => (
                <li key={index}>
                  <Link
                    to={item.url}
                    className={
                      location.pathname.includes(item.url) ? "active" : ""
                    }
                  >
                    {item.page}
                  </Link>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};
export default AdminSidebar;
