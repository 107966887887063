import { Outlet } from "react-router-dom";
import { Suspense, lazy } from "react";
import adsRoute, { AdsLayout } from "./component/ads/routes.helper";
import settingsRoute from "./component/settings/routes.helper";
import NotFound from "./utils/not-found.page";
import PublicBillboardDetails from "./component/billboard/public/public-billboard";
import SettingLayout from "./component/settings/setting.layout";
import VerifyOtp from "./auth/verifyOtp";

// import Dashboard_bg from "./images/dashboard_bg.png";
// import { Box, Container } from "@mui/material";
// import SplashHome from "./user";
// import "./user/userstyle.css";
// import UserLogin from "./user/login";
// import Codeverification from "./user/code-verification";
// import UserAds from "./user/ads";
// import Information from "./user/information";
// import AdvertisingPolicy from "./user/advertising-policy";
// import UploadAds from "./user/upload-ads";
// import UserDashboard from "./user/dashboard";
// import Profile from "./user/profile";
// import ManageProfile from "./user/profile/manage-profile";
const ForgotPassword = lazy(() => import("./auth/ForgotPassword"));
const Login = lazy(() => import("./auth/Login"));
const ResetPassword = lazy(() => import("./auth/ResetPassword"));
const Homeview = lazy(() => import("./component/home"));
const StatisticsView = lazy(() => import("./component/home/StatisticsView"));
const BillboardDetails = lazy(() =>
  import("./component/billboard/detail/billboard-detail.page")
);
const SelectBillboard = lazy(() =>
  import("./component/billboard/detail/SelectBillboard")
);
const BillboardEdit = lazy(() =>
  import("./component/billboard/edit/billboard-edit.page")
);
const BillboardAdd = lazy(() =>
  import("./component/billboard/add/billboard-add.page")
);
const AutomationCheck = lazy(() =>
  import("./component/automation-check/automation-check.component")
);
const TicketComponent = lazy(() => import("./component/ticket/ticket.page"));
const MainLayout = lazy(() => import("./component/layout/main"));
const UserProvider = lazy(() => import("./component/layout/user.provider"));
const ProfileView = lazy(() => import("./component/profile"));
const ProfileEdit = lazy(() => import("./component/profile/ProfileEdit"));

const routes = [
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/",
    element: (
      <Suspense fallback="Loading...">
        <UserProvider>
          <Outlet />
        </UserProvider>
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: <MainLayout ChildElement={Homeview} />,
      },
      {
        path: "ticket",
        element: <TicketComponent />,
      },
      {
        path: "statistics",
        element: <StatisticsView />,
      },
      {
        path: "billboards/:name",
        element: <MainLayout ChildElement={BillboardDetails} />,
      },
      {
        path: "billboard-details",
        element: <SelectBillboard />,
      },
      {
        path: "billboard-setting",
        element: <MainLayout ChildElement={BillboardEdit} />,
      },
      {
        path: "automation-check",
        element: <AutomationCheck />,
      },
      {
        path: "automation-check/:billboard",
        element: <AutomationCheck />,
      },
      {
        path: "billboards/:name/edit",
        element: <MainLayout ChildElement={BillboardEdit} />,
      },
      {
        path: "billboards/add",
        element: <MainLayout ChildElement={BillboardAdd} />,
      },

      {
        path: "my-profile",
        element: <ProfileView />,
      },
      {
        path: "my-profile/edit/",
        element: <ProfileEdit />,
      },

      ...settingsRoute.map((route) => ({
        path: route.path,
        element: (
          <SettingLayout permission={route.permission}>
            {route.element}
          </SettingLayout>
        ),
      })),
      ...adsRoute.map((route) => ({
        path: route.path,
        element: (
          <AdsLayout permission={route.permission}>{route.element}</AdsLayout>
        ),
      })),
    ],
  },
  {
    path: "/auth",
    element: <Login />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/verify-otp/:email",
    element: <VerifyOtp />,
  },
  {
    path: "/reset-password/:id",
    element: <ResetPassword />,
  },
  {
    path: "/billboard-snap/:id",
    element: <PublicBillboardDetails />,
  },
];

export default routes;
