import { Box, Button, Typography } from "@mui/material"
import React from "react"

import { ALERT_MESSAGES } from "../../constants/common"
import SVG from "../../assets/svg"

function ConfirmDialogBox({
  onClose,
  textMessage,
  buttonTitle = "Confirm",
  handleButton,
  isLoading,
  name,
  icon,
}) {
  return (
    <Box
      className="warning"
      sx={{ padding: "1rem", width: "25rem !important" }}
    >
      <Box className="Description">
        <Box gap={1} display="flex" alignItems="flex-end">
          {/* <SVG.Alert /> */}
          <img src={icon === "delete" ? SVG.Alert : SVG.Warning} alt="Alert" />
          <Typography
            variant="h6"
            sx={{
              fontSize: "1.25rem !important",
              fontWeight: "500 !important",
            }}
          >
            Warning
          </Typography>
        </Box>
        <Typography
          variant="h6"
          className="detail"
          sx={{
            fontSize: "1rem !important",
            fontWeight: "400",
            color: "rgb(102, 102, 102)",
            margin: "10px 0px",
          }}
        >
          {textMessage}
        </Typography>

        <Box
          className="Layout"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Button className="cancel" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            name={name ? name : "deleteBox"}
            disabled={isLoading}
            onClick={handleButton}
            className="Alert"
          >
            {isLoading ? ALERT_MESSAGES.submittingLoading : buttonTitle}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ConfirmDialogBox
