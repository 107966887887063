const constants = {
    heatMapDetail: 'HETA-MAP-DETAILS',
    HomeData:'HOME_DATA',
    BillBoardData:"BILL_BOARD_DATA",
    UserData:"USER_DATA",
    billBoardData:"BILL_BOARD",
    offlinebillBoardData:"OFFLINE_BILLBOARD_DATA",
    cameraBillBoard:"CAMERA_BILL_BOARD",
    loginData:"LOGIN_DATA",
    billBoardDetail:"BILL_BOARD_DETAIL"

}
export default constants;
