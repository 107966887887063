import { Card, CardContent, Grid } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { GetSelf } from "../../../api/auth";
import { MENU_ITEMS } from "./helper";
import "./sidebar.css";
import {
  ExpandCircleDown,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";

const Sidebar = ({ children }) => {
  const location = useLocation();
  const [profileData, setProfileData] = React.useState([]);
  const [selected, setSelected] = React.useState("");

  // Get Profile Detalis
  React.useEffect(() => {
    const getdetalis = async () => {
      const response = await GetSelf();
      if (response.remote === "success") {
        setProfileData(response.data.data.doc);
      } else {
      }
    };
    getdetalis();

    setSelected(
      MENU_ITEMS.find((m) => location.pathname.includes(m.URL))?.key || ""
    );
  }, []);

  return (
    <>
      <Grid item xs={12} lg={2}>
        <Card
          variant="false"
          className="card-content"
          sx={{ minHeight: "100%" }}
        >
          <CardContent>
            <ul className="menu">
              {MENU_ITEMS.filter((i) =>
                (profileData.permission || []).includes(i.permission)
              ).map((item, index) => (
                <li key={index} className={item.class}>
                  <Link
                    to={!!item?.children?.length ? undefined : item.URL}
                    className={
                      location.pathname.includes(item.URL) ? "active" : ""
                    }
                    onClick={() => {
                      setSelected(selected === item.key ? "" : item.key);
                    }}
                  >
                    {item.page}
                    {item?.children?.length && selected !== item.key && (
                      <KeyboardArrowDown />
                    )}
                    {item?.children?.length && selected === item.key && (
                      <KeyboardArrowUp />
                    )}
                  </Link>
                  <ul className="submenu">
                    {item.key === selected &&
                      item.children?.map((data) => (
                        <li key={data.url}>
                          <Link
                            to={data.url}
                            className={
                              location.pathname.includes(data.url)
                                ? "active"
                                : ""
                            }
                            onClick={() => {
                              setSelected(selected);
                            }}
                          >
                            {data.page}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};
export default Sidebar;
