import moment from "moment"

import { MAIN_DATE_FORMAT, MAIN_TIME_FORMAT } from "../constants/dates"

function formatTwoValues(val1, val2, symbol = ",") {
  return val1 ? `${val1}${symbol} ${val2}` : val2 || ""
}

export function formatDateUtil(dateString, flag, formatDate = MAIN_DATE_FORMAT) {
  const dateTime = moment(dateString)
  if (flag) {
    return dateString ? formatTwoValues(dateTime.format(formatDate), dateTime.format(MAIN_TIME_FORMAT)) : ""
  }
  return dateString ? dateTime.format(formatDate) : ""
}
