
export const humaniseNumber = (number) => {
    if (typeof number !== "number") {
        return number || 0
    }
    return number > Math.pow(10, 9) ?
        `${(number / Math.pow(10, 9)).toFixed(2)}B` : number > Math.pow(10, 6) ?
            `${(number / Math.pow(10, 6)).toFixed(2)}M` : number > Math.pow(10, 3) ?
                `${(number / Math.pow(10, 3)).toFixed(2)}K` : number.toFixed(2)
}

export const sortBillboards = (a, b) => parseInt(a.replace(/\D/g, "")) - parseInt(b.replace(/\D/g, ""))
