/* eslint-disable jsx-a11y/iframe-has-title */
import {
  Card,
  CardContent,
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import clock from "../../../images/clock.png";
import eye from "../../../images/eye.png";
import ContactDetails from "./contact-details";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useCallback } from "react";
import { useState } from "react";
import { getBillboard } from "./public-billboard.api";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import SimpleMap from "../edit/map";
import moment from "moment";
import { baseURLImg } from "../../../api/api";
import DetailsChart from "../../home/component/chart/DetailsChart";
import { humaniseNumber } from "../../../utils/helper";

const PublicBillboardDetails = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const { id } = useParams();
  const [billboard, setBillboard] = useState({
    data: null,
    error: false,
  });
  const openLightbox = useCallback((index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(() => {
    getBillboard(id)
      .then(({ data }) => {
        if (data) {
          setBillboard((prev) => ({ ...prev, data: data?.data }));
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        setBillboard((prev) => ({ ...prev, error: true }));
      });
  }, [id]);

  const count = billboard.data
    ? [
        {
          id: 1,
          title: Object.entries(billboard.data.views_impression).reduce(
            (acc, curr) =>
              acc.value > curr[1] ? acc : { key: curr[0], value: curr[1] },
            { key: "NA", value: 0 }
          ).key,
          label: "Today Rush Hour",
          icon: clock,
          maxWidth: "20.666667%",
          flexBasis: "20.666667%",
        },
        {
          id: 2,
          title: humaniseNumber(billboard.data.this_week),
          label: "This Week",
          icon: eye,
          maxWidth: "18.666667%",
          flexBasis: "18.666667%",
        },
        {
          id: 3,
          title: humaniseNumber(billboard.data.this_year),
          label: "This Month",
          icon: eye,
          maxWidth: "18.666667%",
          flexBasis: "18.666667%",
        },
        {
          id: 4,
          title: humaniseNumber(billboard.data.this_year),
          label: "This Year",
          icon: eye,
          maxWidth: "18.666667%",
          flexBasis: "17.666667%",
        },
        {
          id: 5,
          title: humaniseNumber(billboard.data.average12Month),
          label: "Average monthly Impressions (last 12 months)",
          icon: eye,
          maxWidth: "23.666667%",
          flexBasis: "23.666667%",
        },
      ]
    : [];

  return billboard.data ? (
    <div className="bg-gray">
      <Container maxWidth="xl">
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item lg={8} xs={12}>
              <Card
                variant="false"
                sx={{
                  boxShadow: "0px 4px 20px rgba(42, 89, 152, 0.05)",
                  borderRadius: "5px",
                }}
              >
                <CardContent sx={{ paddingBottom: "16px !important" }}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "20px",
                      fontWeight: 500,
                      lineHeight: "32px",
                      "@media (max-width:768px)": {
                        lineHeight: "normal",
                        fontSize: "9px",
                      },
                    }}
                  >
                    Billboard Name:{" "}
                    <Box
                      variant="h2"
                      component={"span"}
                      sx={{
                        fontSize: "24px",
                        color: "#3AC4F2",
                        fontWeight: 600,
                        marginLeft: "5px",
                        "@media (max-width:768px)": {
                          lineHeight: "normal",
                          fontSize: "12px",
                        },
                      }}
                    >
                      {billboard.data.billboard_name}
                    </Box>
                    <Box
                      component={"span"}
                      sx={{
                        color: "rgba(34, 41, 67, 0.6)",
                        fontSize: "20px",
                        "@media (max-width:768px)": {
                          lineHeight: "normal",
                          fontSize: "9px",
                        },
                      }}
                    >
                      (Billboard Details)
                    </Box>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={4} xs={12}>
              <Card
                variant="false"
                sx={{
                  boxShadow: "0px 4px 20px rgba(42, 89, 152, 0.05)",
                  borderRadius: "5px",
                }}
              >
                <CardContent sx={{ paddingBottom: "16px !important" }}>
                  <Typography
                    sx={{
                      fontSize: "20px",

                      color: "rgba(34, 41, 67, 0.7)",
                      "@media (max-width:768px)": {
                        lineHeight: "normal",
                        fontSize: "14px",
                      },
                    }}
                  >
                    Installation Date:
                    <Box
                      component={"span"}
                      sx={{
                        fontWeight: 600,
                        marginLeft: "5px",
                      }}
                    >
                      {billboard.data.installationDate
                        ? moment(billboard.data.installationDate).format(
                            "MMM DD YYYY"
                          )
                        : "Not Available"}
                    </Box>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Card
                variant="false"
                sx={{
                  boxShadow: "0px 4px 20px rgba(42, 89, 152, 0.05)",
                  borderRadius: "5px",
                }}
              >
                <CardContent sx={{ paddingBottom: "16px !important" }}>
                  <Grid container spacing={1}>
                    {count.map((item, index) => (
                      <Grid
                        item
                        lg={2}
                        xs={12}
                        key={index}
                        sx={{
                          "@media(min-width:992px)": {
                            maxWidth: item.maxWidth,
                            flexBasis: item.flexBasis,
                          },
                        }}
                      >
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems="center"
                          sx={{
                            background: "#086D77",
                            borderRadius: "8px",
                            padding: "8px",
                            minHeight: "84px",
                          }}
                        >
                          <Box
                            component={"span"}
                            sx={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "100%",
                              padding: "5px",
                              background: "#fff",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              "& img": {
                                width: "20px",
                              },
                            }}
                          >
                            <img src={item.icon} alt="" />
                          </Box>
                          <Box sx={{ width: "75%" }}>
                            <Typography
                              sx={{
                                fontSize: "30px",
                                fontWeight: "600",
                                color: "#fff",
                                lineHeight: "30px",
                              }}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#fff",
                              }}
                            >
                              {item.label}
                            </Typography>
                          </Box>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                  <Card variant="false" className="card-content-2 h-100">
                    <CardContent className="pb-3">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <div className="card-content-title mb-3">
                          Views and Impressions
                        </div>
                        <IconButton></IconButton>
                      </Stack>

                      <div style={{ height: "400px", width: "100%" }}>
                        <DetailsChart data={billboard.data.views_impression} />
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} xs={12}>
                  <ContactDetails data={{...billboard.data,hideData:true}} />
                </Grid>
                <Grid item lg={3} xs={12}>
                  <Card variant="false" className="card-content-2 h-100">
                    <CardContent className="pb-3">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <div className="card-content-title mb-3">
                          Billboard Location
                        </div>
                      </Stack>
                      <Box sx={{ height: "400px", width: "100%" }}>
                        {!!Object.keys(billboard?.data?.coordinates || {})
                          .length ||
                        !!billboard?.data?.location?.coordinates?.length ? (
                          <SimpleMap
                            markerPos={
                              !!Object.keys(billboard?.data?.coordinates || {})
                                .length
                                ? [
                                    billboard?.data?.coordinates?.lat,
                                    billboard?.data?.coordinates?.lang,
                                  ]
                                : billboard?.data?.location?.coordinates
                            }
                          />
                        ) : (
                          "Location Not Available"
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Box sx={{ mt: 2 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
                >
                  <Grid item lg={7} xs={12}>
                    <Card variant="false" className="card-content-2 h-100">
                      <CardContent className="pb-3">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <div className="card-content-title mb-3">
                            Upload gallery images
                          </div>
                        </Stack>
                        <Grid container spacing={1.25}>
                          {billboard.data.gallery.map((image, index) => (
                            <Grid item lg={2} xs={6} key={index}>
                              <Box
                                sx={{
                                  position: "relative",
                                  "@media (max-width:992px)": {
                                    "& img": {
                                      height: "auto !important",
                                    },
                                  },
                                }}
                              >
                                <img
                                  onClick={() => openLightbox(index)}
                                  key={index}
                                  src={`${baseURLImg}/billboards/${image}`}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    cursor: "pointer",
                                    objectFit: "cover",
                                  }}
                                />
                                <Box
                                  onClick={() => openLightbox(index)}
                                  component={"span"}
                                  sx={{
                                    position: "absolute",
                                    bottom: "6px",
                                    right: "2px",
                                  }}
                                >
                                  {billboard.data.gallery.length - 1 ===
                                    index && (
                                    <Button
                                      sx={{
                                        borderRadius: "15px",
                                        border: "1px solid #fff",
                                        background: "rgba(0, 0, 0, 0.6)",
                                        color: "#fff",
                                        padding: "0px 10px",
                                        height: "45px",
                                        "@media (max-width:992px)": {
                                          padding: "6px 8px",
                                          height: "auto",
                                        },
                                      }}
                                    >
                                      {billboard.data.gallery.length} Photos
                                    </Button>
                                  )}
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                          {!billboard.data.gallery.length && (
                            <h5>No Image Available</h5>
                          )}
                        </Grid>
                        <Box
                          sx={{
                            "& button": {
                              background: "#000",
                            },
                          }}
                        >
                          <ModalGateway>
                            {viewerIsOpen ? (
                              <Modal onClose={closeLightbox}>
                                <Carousel
                                  currentIndex={currentImage}
                                  views={billboard.data.gallery.map((x) => ({
                                    src: `${baseURLImg}/billboards/${x}`,
                                  }))}
                                />
                              </Modal>
                            ) : null}
                          </ModalGateway>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={5} xs={12}>
                    <Stack direction={"column"} spacing={2}>
                      <Card variant="false" className="card-content-2 h-100">
                        <CardContent className="pb-3">
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <div className="card-content-title mb-3">
                              Advertising Content
                            </div>
                          </Stack>
                          <div className="">
                            <Stack
                              direction="row"
                              spacing={0}
                              alignItems="center"
                              className="mb-3"
                            >
                              <div className="advertising-box">
                                <span>Amount ADS displayed</span> <span>:</span>
                              </div>
                              <Box
                                sx={{ width: "35%", textAlign: "center" }}
                                className="advertising-count"
                              >
                                {billboard.data.ads || 0}
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={0}
                              alignItems="center"
                              className="mb-3"
                            >
                              <div className="advertising-box">
                                <span>Spots per AD</span> <span>:</span>
                              </div>
                              <Box
                                className="advertising-count"
                                sx={{ width: "35%", textAlign: "center" }}
                              >
                                {billboard.data.spots || 0}
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={0}
                              alignItems="center"
                              className="mb-3"
                            >
                              <div className="advertising-box">
                                <span>Daily Cost</span> <span>:</span>
                              </div>
                              <Box
                                className="advertising-count"
                                sx={{ width: "35%", textAlign: "center" }}
                              >
                                ${humaniseNumber(billboard.data.daily_cost)}
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={0}
                              alignItems="center"
                              className="mb-3"
                            >
                              <div className="advertising-box">
                                <span>Monthly Cost</span> <span>:</span>
                              </div>
                              <Box
                                className="advertising-count"
                                sx={{ width: "35%", textAlign: "center" }}
                              >
                                ${humaniseNumber(billboard.data.monthly_cost)}
                              </Box>
                            </Stack>
                          </div>
                        </CardContent>
                      </Card>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  ) : billboard.error ? (
    <div style={{ padding: 20, textAlign: "center" }}>
      <h1>Your token is used or Invalid to see billboard details.</h1>
    </div>
  ) : (
    <div>Getting Details...</div>
  );
};
export default PublicBillboardDetails;
